::-webkit-scrollbar { 
  width: 0;
}
.hidenav { overflow: -moz-scrollbars-none; }
.hidenav { -ms-overflow-style: none; }

.spotify {
  background-color: #1DB954;
  color: 'white';
  width: 700;
}